import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import type {StatProps} from '@/components/plus/modules/Stats/Stat';
import {twMerge} from '@/stylesheets/twMerge';
import Stat from '@/components/plus/modules/Stats/Stat';

interface StatsProps {
  stats: StatProps[];
  mode?: 'light' | 'customLight' | 'dark';
  className?: string;
}

export default function Stats({
  stats,
  mode = 'light',
  className = '',
}: StatsProps) {
  const numberOfColumns =
    stats.length === 1
      ? 1
      : stats.length === 2
        ? 2
        : stats.length === 3
          ? 3
          : 4;

  return (
    <CardGrid
      numberOfColumns={numberOfColumns}
      withContainer={true}
      className={twMerge('xs:grid-cols-2 gap-y-xl', className)}
    >
      {stats.map((stat, index) => (
        <Stat key={index} mode={mode} {...stat} />
      ))}
    </CardGrid>
  );
}
